$bg-color: #faf7ff;

// Page Specific Stuff

.intro {
  h1 {
    font-size: 6em;
    margin-bottom: 0;

    &:before {
      $logo-width: 1.0em;
      $logo-height: 1.0em;

      content: " ";
      display: inline-block;
      background-image: url(/assets/images/logo.png);
      background-size: $logo-width $logo-height;
      margin-right: $logo-width / 4;
      width: $logo-width;
      height: $logo-height;
    }
  }

  h2 {
    font-style: normal;
    font-size: 3em;
    margin-bottom: 0.2em;
    margin-top: 0.2em;
  }

  p {
    font-size: 1.5em;
    max-width: 40em;
  }

  img {
    max-height: 2em;
    object-fit: contain;
  }
}

.speaking {
  h2 {
    font-size: 3em;
    &:before {
      $logo-height: 1.0em;
      $logo-width: 1 * $logo-height; // tiger is square
      content: " ";
      display: inline-block;
      background-image: url(/assets/images/podium-tiger.png);
      background-size: $logo-width $logo-height;
      margin-right: $logo-width / 4;
      width: $logo-width;
      height: $logo-height;
    }
  }
}

.writing {
  article {
    padding: 0; // porcupine kite flies into the white space, so we don't need padding
  }

  h2 {
    font-size: 3em;
    &:before {
      $logo-height: 3.0em;
      $logo-width: 0.5222 * $logo-height; // porcupine is 94x180, ratio of 0.5222
      content: " ";
      display: inline-block;
      background-image: url(/assets/images/kite-porcupine.png);
      background-size: $logo-width $logo-height;
      margin-right: $logo-width / 4;
      width: $logo-width;
      height: $logo-height;
    }
  }
}


// Global Stuff

// Since we're using dracula pygments theme, code blocks have a dark background
// and therefore need more padding.
pre.highlight > code {
  padding: 1em 0 1em 1em;
}

// --- START NAV

#footerMenu {
  text-align: center;
  padding: 0;
}

#footerMenu ul {
  margin: 0;
}

#footerMenu ul li {
  list-style: none;
  display: inline;

  &:after {
    content: "\00b7";
    padding: 0 0.5em;
  }

  &:last-child:after {
    content: none;
  }
}

// --- END NAV


// Fix emoji from getting stretched by tufte.css on narrow viewports
img.emoji {
  width: 20px;
}
